<template>
  <app-bar
    title="Data Diri"
    :previousLink="{
      name: 'Profil'
    }"
    :noBackButtonOnDesktop="true"
    class="sub"
  />

  <Form
    @submit="update"
    v-slot="{ errors }"
    class="mt-3 mt-md-2 mb-4 mb-md-0"
  >
    <!-- Foto Profil -->
    <section class="d-flex mb-3">
      <div class="avatar-wrapper bg-neutral-200 rounded-circle">
        <img
          :src="fotoSrc ? fotoSrc : '@/assets/icon/profile-circle.svg'"
          :alt="user.pas_nama"
          :title="user.pas_nama"
          class="avatar-image rounded-circle">
      </div>

      <image-rotator
        :imageURL="fotoBlob"
        @done="onFotoRotated"
        :show="$route.query.showPopupFoto === 'true'"
        :imageName="fotoName"
        @cancel="$router.replace({
          query: { ...$route.query, showPopupFoto: null }
        })" />

      <input
        ref="foto"
        type="file"
        accept="image/jpeg,image/jpg,image/png"
        @change="onFotoChange($event.target.files[0])"
        class="d-none">

      <div class="upload-group d-flex flex-column justify-content-between align-items-start ms-3">
        <button
          @click="$refs.foto.click()"
          class="btn btn-upload bg-primary-50 d-flex align-items-center rounded-pill gap-2"
          type="button">
          <img
            src="@/assets/icon/folder-open.svg"
            alt="Icon"
          >
          <span class="fw-600 fs-12 lh-18 color-primary-500">Pilih Foto Profil</span>
        </button>

        <span class="upload-desc fw-400 fs-12 lh-22 color-neutral-700 mb-0">
          Gunakan format .jpg atau .png, maksimal ukuran file 2MB
        </span>
      </div>
    </section>

    <!-- Foto KTP -->
    <!-- <section class="d-flex flex-column mb-4">
      <div class="image-wrapper bg-neutral-200 d-flex align-items-center justify-content-center mb-2">
        <img
          :src="ktpSrc ? ktpSrc : '@/assets/icon/card.svg'"
          :alt="user.pas_nama"
          :title="user.pas_nama"
          class="image-ktp shadow">
      </div>

      <image-rotator
        :imageURL="ktpBlob"
        @done="onKtpRotated"
        :show="$route.query.showPopupKtp === 'true'"
        :imageName="ktpName"
        @cancel="$router.replace({
          query: { ...$route.query, showPopupKtp: null }
        })" />

      <input
        ref="ktp"
        type="file"
        accept="image/jpeg,image/jpg,image/png"
        @change="onKtpChange($event.target.files[0])"
        class="d-none">

      <div class="d-flex flex-column justify-content-between align-items-start">
        <button
          @click="$refs.ktp.click()"
          class="btn btn-upload bg-primary-50 d-flex align-items-center justify-content-center rounded-pill gap-2 w-100"
          type="button">
          <img
            src="@/assets/icon/folder-open.svg"
            alt="Icon"
          >
          <span class="fw-600 fs-12 lh-18 color-primary-500">Foto KTP</span>
        </button>
      </div>
    </section> -->

    <!-- Nama -->
    <section class="mb-3">
      <label>Nama</label>
      <div class="input-group">
        <Field
          v-model="form.pas_nama"
          name="pas_nama"
          rules="required"
          class="form-control"
          :class="{ 'is-invalid': errors.pas_nama }"
          placeholder="Nama Lengkap"
        />
        <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errors.pas_nama || errorMessage }">
          <div class="show-password" type="button" @click="form.pas_nama = ''" :class="{'opacity-0':!form.pas_nama}">
            <i class="ri-close-circle-fill fs-24"></i>
          </div>
        </span>
        <span v-if="!errors.pas_nama" class="invalid-feedback">{{ errorMessage }}</span>
        <ErrorMessage name="pas_nama" class="invalid-feedback" />
      </div>
    </section>

    <!-- No KTP -->
    <section class="mb-3">
      <label>No. KTP</label>
      <div class="input-group">
        <Field
          type="number"
          min="0"
          max="16"
          v-model="form.pas_noktp"
          name="pas_noktp"
          rules="required|digits:16"
          class="form-control"
          :class="{ 'is-invalid': errors.pas_noktp }"
          placeholder="Nomor KTP"
          @change="onChangeKTPNumber($event)"
        />
        <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errors.pas_noktp || errorMessage }">
          <div class="show-password" type="button" @click="form.pas_noktp = ''" :class="{'opacity-0':!form.pas_noktp}">
            <i class="ri-close-circle-fill fs-24"></i>
          </div>
        </span>
        <span v-if="!errors.pas_noktp" class="invalid-feedback">{{ errorMessage }}</span>
        <ErrorMessage name="pas_noktp" class="invalid-feedback" />
      </div>
    </section>

    <!-- Tanggal Lahir -->
    <section class="mb-3">
      <label>Tanggal Lahir</label>
        <div class="input-group mb-3 row-flex justify-content-between gap-1">
          <div class="col-3">
            <Field
              type="number"
              v-model="date.tgl"
              name="tgllahir"
              rules="required"
              class="form-control"
              min="1"
              placeholder="Tanggal"
              @change="onChangeDate($event)"
              :disabled="!user.status_text"
            />
          </div>
          <div class="col-5">
            <select
              class="form-select select-form color-neutral-900"
              id="input-month"
              v-model="date.bln"
              name="bulan"
              rules="required"
              :disabled="!user.status_text"
            >
                <option selected disabled>Bulan</option>
                <option value="01">Januari</option>
                <option value="02">Februari</option>
                <option value="03">Maret</option>
                <option value="04">April</option>
                <option value="05">Mei</option>
                <option value="06">Juni</option>
                <option value="07">Juli</option>
                <option value="08">Agustus</option>
                <option value="09">September</option>
                <option value="10">Oktober</option>
                <option value="11">November</option>
                <option value="12">Desember</option>
            </select>
          </div>
          <div class="col-3">
            <Field
              type="number"
              v-model="date.thn"
              name="tahun"
              rules="required"
              max="2022"
              class="form-control"
              placeholder="Tahun"
              :disabled="!user.status_text"
            />
          </div>
          <ErrorMessage name="tgllahir" class="invalid-feedback" />
        </div>
      <ErrorMessage name="pas_tgllahir" class="invalid-feedback" />
    </section>

    <!-- Jenis Kelamin -->
    <section class="mb-3">
      <label class="mb-2">Jenis Kelamin</label>
      <div class="d-flex justify-content-between gap-2 mb-4">
        <div
          class="form-check w-100"
          :class="{
            'disabled': !user.status_text
          }"
        >
          <div
            @click="form.pas_jenkelamin = 'L'"
            class="btn btn-gender d-flex justify-content-between align-items-center rounded-pill"
            :class="{ active: (form.pas_jenkelamin === 'L') }"
          >
            <p class="fst-normal fw-600 fs-12 lh-16 mb-0">Laki-laki</p>
            <div
              class="box-icon rounded-circle d-flex align-items-center justify-content-center"
              :class="{active: (form.pas_jenkelamin === 'L') }">
                <i class="ri-men-line icon-gender"></i>
            </div>
          </div>
        </div>
        <div
          class="form-check w-100"
          :class="{
            'disabled': !user.status_text
          }"
        >
          <div
            @click="form.pas_jenkelamin = 'P'"
            class="btn btn-gender d-flex justify-content-between align-items-center rounded-pill"
            :class="{ active: (form.pas_jenkelamin === 'P') }"
          >
            <p class="fst-normal fw-600 fs-12 lh-16 mb-0">Perempuan</p>
            <div
              class="box-icon rounded-circle d-flex align-items-center justify-content-center"
              :class="{ active: (form.pas_jenkelamin === 'P') }">
                <i class="ri-women-line icon-gender"></i>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Email -->
    <section class="mb-3">
      <label>Email</label>
      <div class="input-group">
        <Field
          v-model="form.email_user"
          name="email_user"
          id="email_user"
          type="email"
          rules="required|emailOrPhone"
          class="form-control"
          :class="{ 'is-invalid': errors.email_user }"
          placeholder="Email"
        />
        <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errors.email_user || errorMessage }">
          <div class="show-password" type="button" @click="form.email_user = ''" :class="{'opacity-0':!form.email_user}">
            <i class="ri-close-circle-fill fs-24"></i>
          </div>
        </span>
        <span v-if="!errors.email_user" class="invalid-feedback">{{ errorMessage }}</span>
        <ErrorMessage name="email_user" class="invalid-feedback" />
      </div>
    </section>

    <!-- No HP -->
    <section class="mb-3">
      <label>No. Telp</label>
      <div class="input-group mb-3">
        <VueTelInput
          :value="form.pas_hp"
          v-model="form.pas_hp"
          name="pas_hp"
          rules="required"
          mode="international"
          class="form-control"
          :class="{ 'is-invalid': errors.pas_hp }"
          :inputOptions="{
            'placeholder': vueTelOption.placeholder
          }"
          :validCharactersOnly="true"
          @input="handleInputHP"
          @change="handleWa"
        />
        <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errors.pas_hp }">
          <div class="show-password" type="button" @click="form.pas_hp = ''" :class="{'opacity-0':!form.pas_hp}">
            <i class="ri-close-circle-fill fs-24"></i>
          </div>
        </span>
        <ErrorMessage name="pas_hp" class="invalid-feedback" />
      </div>
    </section>

    <!-- No Whatsapp -->
    <section v-if="!sameAsWa" class="mb-3">
      <label>WhatsApp</label>
      <div class="input-group mb-3">
        <VueTelInput
          :value="form.pas_wa"
          v-model="form.pas_wa"
          name="pas_wa"
          rules="required"
          mode="international"
          class="form-control"
          :class="{ 'is-invalid': errors.pas_wa }"
          :inputOptions="{
            'placeholder': vueTelOption.placeholder
          }"
          :validCharactersOnly="true"
          @input="handleInputWA"
          @change="handleWa"
        />
        <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errors.pas_wa }">
          <div class="show-password" type="button" @click="form.pas_wa = ''" :class="{'opacity-0':!form.pas_wa}">
            <i class="ri-close-circle-fill fs-24"></i>
          </div>
        </span>
        <ErrorMessage name="pas_wa" class="invalid-feedback" />
      </div>
    </section>

    <section class="mb-3">
      <div class="input-group mb-2">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="telpSame"
            v-model="sameAsWa"
            @change="handleWa"
          >
          <label class="form-label pt-1 ms-2 mb-0 fw-400 fs-12 lh-22 color-neutral-700" for="telpSame">
            No. Telp sama dengan WhatsApp
          </label>
        </div>
      </div>
    </section>

    <!-- Alamat -->
    <section class="mb-3">
      <label>Alamat</label>
      <div class="input-group">
        <textarea
          v-model="form.pas_alamat"
          name="pas_alamat"
          rules="required"
          class="form-control"
          :class="{ 'is-invalid': errors.pas_alamat }"
          placeholder="Alamat lengkap"
        />
        <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errors.pas_alamat || errorMessage }">
          <div class="show-password" type="button" @click="form.pas_alamat = ''" :class="{'opacity-0':!form.pas_alamat}">
            <i class="ri-close-circle-fill fs-24"></i>
          </div>
        </span>
        <span v-if="!errors.pas_alamat" class="invalid-feedback">{{ errorMessage }}</span>
        <ErrorMessage name="pas_alamat" class="invalid-feedback" />
      </div>
    </section>

    <!-- Provinsi -->
    <section class="mb-3">
      <label>Provinsi</label>
      <div class="input-group mb-3 row-flex justify-content-between gap-1">
        <select
          v-model="form.kode_provinsi"
          name="kode_provinsi"
          id="kode_provinsi"
          rules="required"
          class="form-select select-form color-neutral-900"
          @change="onChangeProvince($event)"
        >
          <option selected disabled>Pilih Provinsi</option>
          <option
            v-for="province in provinces"
            :key="province"
            :value="province.kode"
          >
            {{ capitalize(province.nama) }}
          </option>
        </select>
      </div>
      <span v-if="!errors.kode_provinsi" class="invalid-feedback">{{ errorMessage }}</span>
      <ErrorMessage name="kode_provinsi" class="invalid-feedback" />
    </section>

    <!-- Kota -->
    <section class="mb-3">
      <label>Kota / Kabupaten</label>
      <div class="input-group mb-3 row-flex justify-content-between gap-1">
        <select
          v-model="form.kode_kota"
          name="kode_kota"
          id="kode_kota"
          rules="required"
          class="form-select select-form color-neutral-900"
          @change="onChangeCity($event)"
        >
          <option selected disabled>Pilih Kota / Kabupaten</option>
          <option
            v-for="city in cities"
            :key="city"
            :value="city.kode"
          >
            {{ capitalize(city.nama) }}
          </option>
        </select>
      </div>
      <span v-if="!errors.kode_kota" class="invalid-feedback">{{ errorMessage }}</span>
      <ErrorMessage name="kode_kota" class="invalid-feedback" />
    </section>

    <!-- Kecamatan -->
    <section class="mb-3">
      <label>Kecamatan</label>
      <div class="input-group mb-3 row-flex justify-content-between gap-1">
        <select
          v-model="form.kode_kecamatan"
          name="kode_kecamatan"
          id="kode_kecamatan"
          rules="required"
          class="form-select select-form color-neutral-900"
          @change="onChangeSubDistrict($event)"
        >
          <option selected disabled>Pilih Kecamatan</option>
          <option
            v-for="subDistrict in subDistricts"
            :key="subDistrict"
            :value="subDistrict.kode"
          >
            {{ capitalize(subDistrict.nama) }}
          </option>
        </select>
      </div>
      <span v-if="!errors.kode_kecamatan" class="invalid-feedback">{{ errorMessage }}</span>
      <ErrorMessage name="kode_kecamatan" class="invalid-feedback" />
    </section>

    <!-- Kelurahan -->
    <section class="mb-3">
      <label>Kelurahan</label>
      <div class="input-group mb-3 row-flex justify-content-between gap-1">
        <select
          v-model="form.kode_kelurahan"
          name="kode_kelurahan"
          id="kode_kelurahan"
          rules="required"
          class="form-select select-form color-neutral-900"
        >
          <option selected disabled>Pilih Kelurahan</option>
          <option
            v-for="ward in wards"
            :key="ward"
            :value="ward.kode"
          >
            {{ capitalize(ward.nama) }}
          </option>
        </select>
      </div>
      <span v-if="!errors.kode_kelurahan" class="invalid-feedback">{{ errorMessage }}</span>
      <ErrorMessage name="kode_kelurahan" class="invalid-feedback" />
    </section>

    <!-- Button -->
    <div class="row mb-0 mb-xs-0">
      <div class="col-md-4 mb-0 mb-md-3 mb-xs-3">
        <button
          class="btn btn-primary w-100 pt-2 pb-2"
          type="submit"
          :disabled="loading.updatePersonalData">
          {{ loading.updatePersonalData ? 'Memperbarui' : 'Simpan' }}
        </button>
      </div>
    </div>
  </Form>
</template>

<script>
import { computed, nextTick, onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { user } from '@/core/auth'
import { provinces, cities, subDistricts, wards, getProvinces, getCities, getSubDistricts, getWards } from '@/core/region'
import { showNotif } from '@/core/utility'
import { updatePersonalData, loading } from '@/core/profile'
import { title } from '@/core/page'
import AppBar from '@/components/AppBar.vue'
import { Field, Form, ErrorMessage } from 'vee-validate'
import ImageRotator from '@/components/utility/ImageRotator.vue'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

export default {
  components: {
    AppBar,
    Field,
    Form,
    ErrorMessage,
    ImageRotator,
    VueTelInput
  },
  setup () {
    nextTick(() => {
      title.value = 'Update Data Diri'
    })

    const router = useRouter()
    const route = useRoute()
    const vueTelOption = ref({
      mode: 'international',
      defaultCountry: 'ID',
      onlyCountries: ['ID'],
      enabledCountryCode: true,
      enabledFlags: true,
      autocomplete: 'off',
      required: true
    })
    const errorMessage = ref('')
    const sameAsWa = ref(false)
    const date = reactive({
      tgl: new Date(user.value.pas_tgllahir).toLocaleDateString('ID', { day: 'numeric' }),
      bln: new Date(user.value.pas_tgllahir).toLocaleDateString('ID', { month: '2-digit' }),
      thn: new Date(user.value.pas_tgllahir).toLocaleDateString('ID', { year: 'numeric' })
    })

    const form = reactive({
      pas_id: user.value.pas_id,
      pas_nama: user.value.pas_nama,
      pas_noktp: user.value.pas_noktp,
      pas_tgllahir: date.thn + '-' + date.bln + '-' + date.tgl,
      pas_alamat: user.value.pas_alamat,
      pas_jenkelamin: user.value.pas_jenkelamin,
      pas_hp: user.value.pas_hp,
      pas_wa: user.value.pas_wa,
      email_user: user.value.email_user,
      kode_provinsi: user.value.kode_provinsi,
      kode_kota: user.value.kode_kota,
      kode_kecamatan: user.value.kode_kecamatan,
      kode_kelurahan: user.value.kode_kelurahan
    })

    let fileFoto = null
    const fotoName = ref(null)
    const fotoBlob = ref(null)

    let fileKTP = null
    const ktpName = ref(null)
    const ktpBlob = ref(null)

    const onFotoChange = async file => {
      fotoName.value = file.name
      fotoBlob.value = URL.createObjectURL(file)
      router.push({
        query: {
          ...route.query,
          showPopupFoto: true
        }
      })
    }

    const onFotoRotated = ({ file, url }) => {
      fileFoto = file
      fotoBlob.value = url
      router.push({
        query: {
          ...route.query,
          showPopupFoto: false
        }
      })
    }

    const onKtpChange = async file => {
      ktpName.value = file.name
      ktpBlob.value = URL.createObjectURL(file)
      router.push({
        query: {
          ...route.query,
          showPopupKtp: true
        }
      })
    }

    const onKtpRotated = ({ file, url }) => {
      fileKTP = file
      ktpBlob.value = url
      router.push({
        query: {
          ...route.query,
          showPopupKtp: false
        }
      })
    }

    const onChangeDate = (param) => {
      date.tgl = param.target.value
    }

    const handleWa = () => {
      if (sameAsWa.value) {
        form.pas_wa = form.pas_hp
      }
    }

    const handleInputHP = (phone, phoneObject) => {
      if (phoneObject?.formatted) {
        form.pas_hp = phoneObject.formatted
      }
    }

    const handleInputWA = (phone, phoneObject) => {
      if (phoneObject?.formatted) {
        form.pas_wa = phoneObject.formatted
      }
    }

    const onChangeProvince = async (event) => {
      const kode = event.target.value
      await getCities(kode)

      // reset form
      form.kode_kecamatan = ''
      form.kode_kelurahan = ''

      // reset list
      subDistricts.value = []
      wards.value = []
    }

    const onChangeCity = async (event) => {
      const kode = event.target.value
      await getSubDistricts(kode)

      // reset form
      form.kode_kelurahan = ''

      // reset list
      wards.value = []
    }

    const onChangeSubDistrict = async (event) => {
      const kode = event.target.value
      await getWards(kode)
    }

    const onChangeKTPNumber = (event) => {
      form.pas_noktp = event.target.value
    }

    const capitalize = (word) => {
      return word.toLowerCase().replace(/\b./g, function (a) {
        return a.toUpperCase()
      })
    }

    const update = async () => {
      form.pas_tgllahir = date.thn + '-' + date.bln + '-' + date.tgl
      const result = await updatePersonalData(form, {
        foto: fileFoto,
        ktp: fileKTP
      })

      if (result === true) {
        showNotif({
          status: 'success',
          message: 'Data diri berhasil diperbarui'
        })

        window.setTimeout(function () {
          // redirect ke home
          const url = new URL('/', window.location.origin)
          window.location.href = url.toString()
        }, 3000)
      }
    }

    const fotoSrc = computed(() => {
      return fotoBlob.value || user.value.foto || require('@/assets/user-placeholder.png')
    })
    const ktpSrc = computed(() => ktpBlob.value || user.value.ktp || require('@/assets/user-placeholder.png'))

    onMounted(async () => {
      await getProvinces()

      if (form.kode_provinsi) {
        await getCities(form.kode_provinsi)
      }

      if (form.kode_kota) {
        await getSubDistricts(form.kode_kota)
      }

      if (form.kode_kecamatan) {
        await getWards(form.kode_kecamatan)
      }

      if (form.pas_wa === form.pas_hp) {
        sameAsWa.value = true
      }
    })

    return {
      user,
      form,
      date,
      errors: {},
      errorMessage,
      loading,
      fotoSrc,
      fotoBlob,
      ktpSrc,
      ktpBlob,
      fotoName,
      ktpName,
      sameAsWa,
      vueTelOption,
      provinces,
      cities,
      subDistricts,
      wards,
      updatePersonalData,
      update,
      onFotoChange,
      onKtpChange,
      onFotoRotated,
      onKtpRotated,
      handleWa,
      handleInputHP,
      handleInputWA,
      onChangeProvince,
      onChangeCity,
      onChangeSubDistrict,
      capitalize,
      onChangeKTPNumber,
      onChangeDate
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  &-wrapper {
    width: 5.75rem;
    height: 5.75rem;

    @media (min-width: 520px) {
      width: 86px;
      height: 86px;
    }
  }

  &-image {
    width: 5.75rem;
    height: 5.75rem;
    object-fit: cover;

    @media (min-width: 520px) {
      width: 86px;
      height: 86px;
    }
  }
}

.upload-group {
  .upload-desc {
    @media (max-width: 520px) {
      max-width: 179px;
    }
  }

  .btn-upload {
    padding: 8px 20px;
  }
}

.image {
  &-wrapper {
    width: 100%;
    border-radius: 12px;

    @media (max-width: 520px) {
      height: 200px;
    }
  }

  &-ktp {
    width: 100%;
    height: 350px;
    border-radius: 12px;
    object-fit: cover;

    @media (max-width: 520px) {
      height: 200px;
    }
  }
}

.form-control, .input-group-text {
  background: none;
  border-radius: 0;
  box-shadow: inset 0px -1px 0px rgba(242, 242, 242, 0.8);
  padding: 8px 4px 8px 0px;
  font-size: 18px;
  border: none;
}

.form-control::placeholder {
  color: #CBD5E0;
}

.form-control.is-invalid, .input-group-text.is-invalid {
  box-shadow: inset 0px -1px 0px #E21F39;
}

.input-group>.form-control.form-error {
  background: #FFDDEB;
  color: #14142B;
}

select.select-form {
  height: 100%;
  font-size: 18px;
  line-height: 24px;
  border: none;
  box-shadow: 0px -1px 0px 0px #F2F2F2CC inset;
  background-image: url("../../../assets/icon/select-arrow.png");
  background-repeat: no-repeat;
  background-size: 14px auto;
}

.btn-gender {
  padding: 4px 4px 4px 12px;
  border-radius: 8px;
  background: #EFF0F7;
  color: #4E4B66;
  width: 100%;
}

.btn-gender.active {
  background: #3566FC;
  color: #FFFFFF;
}

.box-icon {
  width: 30px;
  height: 30px;
  color: #4A5568;
  background: #FFFFFF;
}

.box-icon.active {
  color: #FFFFFF;
  background: rgba(255, 255, 255, 0.35);
}

.form-check {
  padding-left: unset;
}

.form-check .form-check-input {
  margin-left: 0;
}

div.disabled {
  pointer-events:none;
}

.form-select.select-form:disabled {
  background: white;
}
</style>
