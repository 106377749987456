import { reactive, ref } from 'vue'
import axios from '../plugins/axios'

export const loading = reactive({
  province: false,
  city: false,
  subDistrict: false,
  ward: false
})

export const provinces = ref([])
export const cities = ref([])
export const subDistricts = ref([])
export const wards = ref([])

export const getProvinces = async () => {
  try {
    loading.province = true

    const { data } = await axios.get('app/provinsi')

    if (data.success) {
      provinces.value = data.data
    } else {
      console.error('Error dari region get provinces = ', data.message)
    }
  } catch (error) {
    console.error(error)
    throw error
  } finally {
    loading.province = false
  }
}

export const getCities = async (kode_provinsi) => {
  try {
    loading.city = true

    const { data } = await axios.get('app/kota', {
      params: {
        provinsi: kode_provinsi
      }
    })

    if (data.success) {
      cities.value = data.data
    } else {
      console.error('Error dari region get cities = ', data.message)
    }
  } catch (error) {
    console.error(error)
    throw error
  } finally {
    loading.city = false
  }
}

export const getSubDistricts = async (kode_kota) => {
  try {
    loading.subDistrict = true

    const { data } = await axios.get('app/kecamatan', {
      params: {
        kota: kode_kota
      }
    })

    if (data.success) {
      subDistricts.value = data.data
    } else {
      console.error('Error dari region get sub districts = ', data.message)
    }
  } catch (error) {
    console.error(error)
    throw error
  } finally {
    loading.subDistrict = false
  }
}

export const getWards = async (kode_kecamatan) => {
  try {
    loading.ward = true

    const { data } = await axios.get('app/kelurahan', {
      params: {
        kecamatan: kode_kecamatan
      }
    })

    if (data.success) {
      wards.value = data.data
    } else {
      console.error('Error dari region get sub wards = ', data.message)
    }
  } catch (error) {
    console.error(error)
    throw error
  } finally {
    loading.ward = false
  }
}
