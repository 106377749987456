<template>
  <section
    v-if="show">
    <nav class="d-flex border-bottom justify-content-between">
      <button
        type="button"
        class="btn btn-transparent text-white btn-lg"
        @click="$emit('cancel')">
        <i class="ri-close-line"></i>
      </button>
      <button
        type="button"
        class="btn btn-transparent text-white btn-lg"
        @click="done">
        <i class="ri-check-line"></i>
      </button>
    </nav>

    <div class="preview">
      <img
        :src="urlFotoPreview">
    </div>

    <div
      class="controls py-3 text-center">
      <button
        type="button"
        class="btn btn-light me-3"
        @click="rotate(90)">
        <i class="ri-anticlockwise-fill"></i>
      </button>
      <button
        type="button"
        class="btn btn-light"
        @click="rotate(-90)">
        <i class="ri-clockwise-fill"></i>
      </button>
    </div>
  </section>
</template>

<script>
import { computed, ref } from 'vue'
import Jimp from 'jimp'
import { urlToFile } from '@/core/utility'
import { analytics } from '@/core/firebase-messaging'

export default {
  props: {
    imageURL: {
      type: String
    },
    imageName: {
      type: String
    },
    show: {
      type: Boolean
    }
  },
  setup (props, { emit }) {
    const previewURL = ref(null)
    /**
     * @type Jimp
     */
    let processedImage = null

    const onImageURLChange = async () => {
      if (previewURL.value === props.imageURL) {
        return
      }

      previewURL.value = props.imageURL
      processedImage = await Jimp.read(previewURL.value)
      processedImage.resize(800, Jimp.AUTO)
    }

    const rotate = async degree => {
      processedImage.rotate(degree)
      previewURL.value = await processedImage.getBase64Async(
        'image/png'
      )

      analytics.logEvent('rotate', { degree })
    }

    const done = async () => {
      const randomName = new Date().getTime()
      const file = await urlToFile(previewURL.value, props.imageName || `${randomName}.png`)
      const url = previewURL.value

      emit('done', {
        file,
        url
      })
    }

    const urlFotoAsli = computed(() => {
      return props.imageURL
    })

    const urlFotoPreview = computed(() => {
      return previewURL.value
    })

    return {
      previewURL,
      urlFotoAsli,
      urlFotoPreview,
      onImageURLChange,
      rotate,
      done
    }
  },
  watch: {
    'imageURL' () {
      this.onImageURLChange()
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 99999;
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .preview {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      padding: 3rem;
      max-height: 80vh;
      max-width: 90vw;
      object-fit: contain;
    }
  }
}
</style>
